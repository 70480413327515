import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";
import Paragraph from "../../Paragraph";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { nav } from "../../../utils/navigation";

interface AnalyzeYourAccountProps {
  title?: string;
  description?: string;
  cta?: {
    text: string;
    route: string;
  };
}

const AnalyzeYourAccount: React.FC<AnalyzeYourAccountProps> = props => {
  const { title, description, cta } = props;

  return (
    <div className="bg-blue-3-light rounded-tl-large rounded-br-large">
      <Section className="pt-20 pb-20">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="w-full lg:w-1/2 mt-12 lg:mt-0 order-2 lg:order-1">
            <StaticImage
              src="../../../images/competitor/perpetua-benchmarker-2-summary.png"
              alt="Benchmarker"
              className="w-full lg:w-10/12 mx-auto lg:mx-0"
            />
          </div>
          <div className="w-full lg:w-1/2 order-1 lg:order-2">
            <Header
              className="md:text-5xl md:font-bold mb-8 lg:max-w-xl text-center md:text-left"
              variant={HeaderVariant.h3}
            >
              {title}
            </Header>
            <Paragraph className="text-center md:text-left">
              {description}
            </Paragraph>
            <Button
              className="mt-8 mx-auto md:mx-0"
              variant={ButtonVariant.primary}
              size={ButtonSize.default}
              text={cta?.text}
              onClick={() => {
                nav(cta?.route);
              }}
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default AnalyzeYourAccount;
