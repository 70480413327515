import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import Section from "../../../containers/Section";
import Helium10AndSellics from "./Helium10AndSellics";

interface AlternativesProps {
  data?: any;
}

const Alternatives: React.FC<AlternativesProps> = props => {
  const { data } = props;

  const helium10AndSellics = [data?.helium10, data?.sellics];

  return (
    <Section className="md:mt-20 lg:mt-24">
      <div className="grid grid-cols-1 lg:grid-cols-2 md:mt-20 lg:mt-24">
        <Header
          className="mb-8 text-center md:text-left max-w-xs pr-3 justify-self-center md:justify-self-start"
          variant={HeaderVariant.h2}
        >
          <span className="block text-3xl font-semibold">{data?.title}</span>
        </Header>
        <div className="lg:max-w-xl relative text-center md:text-left">
          {data?.description.map((item: string, index: number) => {
            return (
              <Paragraph
                key={index}
                className="flex mb-3 items-center text-gray-3-dark"
              >
                <span>{item}</span>
              </Paragraph>
            );
          })}
        </div>
      </div>
      <Helium10AndSellics helium10AndSellics={helium10AndSellics} />
    </Section>
  );
};

export default Alternatives;
