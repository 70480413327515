import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";
import GradientTitle from "../../GradientTitle";
import Paragraph from "../../Paragraph";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { nav } from "../../../utils/navigation";

interface UnderstandYourProfitProps {
  gradientTitle?: string[];
  gradientPosition?: number;
  description?: string;
  cta?: {
    text?: string;
    route?: string;
  };
}

const UnderstandYourProfit: React.FC<UnderstandYourProfitProps> = props => {
  const { gradientTitle, description, gradientPosition, cta } = props;

  return (
    <Section className="pt-20 pb-20">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/2 text-center md:text-left">
          <GradientTitle
            gradientPosition={gradientPosition}
            className="text-2xl font-semibold mb-6 md:text-4xl md:mb-8 lg:max-w-xl"
            color="purple"
            variant={HeaderVariant.h2}
            title={gradientTitle}
            lineBreak={false}
          />
          <Paragraph className="lg:max-w-xl">{description}</Paragraph>
          <Button
            className="mt-8 mx-auto md:mx-0"
            variant={ButtonVariant.secondary}
            size={ButtonSize.default}
            text={cta?.text}
            onClick={() => {
              nav(cta?.route);
            }}
          />
        </div>
        <div className="w-full lg:w-1/2 mt-12 lg:mt-0 ">
          <StaticImage
            src="../../../images/competitor/understand-profit.png"
            alt="understand your profit"
            className="w-full md:10/12 mx-auto lg:mx-0"
          />
        </div>
      </div>
    </Section>
  );
};

export default UnderstandYourProfit;
