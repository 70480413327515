import React, { useState } from "react";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";
import { UITable, TableRow, TableHeader } from "@perpetua1/oasis-react";
import Icon, { IconType } from "../../Icon";

interface Helium10VsSellicsProps {
  gradientTitle?: string[];
  gradientPosition?: number;
  subtitle?: string;
}

const Helium10VsSellics: React.FC<Helium10VsSellicsProps> = props => {
  const { gradientTitle, gradientPosition, subtitle } = props;

  type FeatureComparison = {
    features: string;
    helium10: any;
    sellics: any;
  };

  type Row = {
    key: string;
    data: FeatureComparison;
  };

  const rows: TableRow[] = [
    {
      key: "col1",
      data: {
        features: "Amazon Keyword Research Tool",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.indigoCheckmark} />
            Free on Sonar
          </div>
        )
      }
    },
    {
      key: "col2",
      data: {
        features: "Reverse ASIN Lookup",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.indigoCheckmark} />
            Free on Sonar
          </div>
        )
      }
    },
    {
      key: "col3",
      data: {
        features: "Keyword Ranking Tracker",
        helium10: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.indigoCheckmark} />
            Free on Sonar
          </div>
        ),
        sellics: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.indigoCheckmark} />
            Unlimited
          </div>
        )
      }
    },
    {
      key: "col4",
      data: {
        features: "Amazon PPC Management",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col5",
      data: {
        features: "Amazon PPC Automation",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col6",
      data: {
        features: "Hijacker Alerts",
        helium10: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.indigoCheckmark} />
            *Limited ASINs
          </div>
        ),
        sellics: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.indigoCheckmark} />
            Unlimited
          </div>
        )
      }
    },
    {
      key: "col7",
      data: {
        features: "Negative Review Alerts",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col8",
      data: {
        features: "Net Profit Calculation",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col9",
      data: {
        features: "CoGs Management",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col10",
      data: {
        features: "Inventory Management",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col11",
      data: {
        features: "Customer Review Management",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col12",
      data: {
        features: "Product Research & Niche Analyzer",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col13",
      data: {
        features: "Competitor Inventory Monitoring",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col14",
      data: {
        features: "Competitor Sales Volume Monitoring",
        helium10: <Icon iconType={IconType.closeGray} />,
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col15",
      data: {
        features: "Amazon Marketplaces (North America & Europe)",
        helium10: (
          <div className="flex items-center font-normal">
            <Icon className="mr-3" iconType={IconType.closeGray} />
            *Varies based on individual features
          </div>
        ),
        sellics: <Icon iconType={IconType.indigoCheckmark} />
      }
    },
    {
      key: "col15",
      data: {
        features: "FBA Reimbursement",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: <Icon iconType={IconType.closeGray} />
      }
    },
    {
      key: "col16",
      data: {
        features: "Email Follow-ups",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: <Icon iconType={IconType.closeGray} />
      }
    },
    {
      key: "col17",
      data: {
        features: "Multiple Seller Central account integration",
        helium10: <Icon iconType={IconType.indigoCheckmark} />,
        sellics: <Icon iconType={IconType.closeGray} />
      }
    }
  ];

  const tableHeaders: TableHeader[] = [
    {
      key: "features",
      title: "Features",
      colspan: 3.5
    },
    {
      key: "helium10",
      title: "Helium 10",
      colspan: 2
    },
    {
      key: "sellics",
      title: <span className="text-indigo-1">Sellics (now Perpetua)</span>,
      colspan: 2,
      cell: ({ row }: { header: any; row: Row; metadata: any }) => (
        <span className="">{row.data.sellics}</span>
      )
    }
  ];

  return (
    <Section className="text-center">
      <GradientTitle
        gradientPosition={gradientPosition}
        className="text-4xl font-semibold mb-6 md:text-5xl md:font-bold md:mb-8"
        color="purple"
        variant={HeaderVariant.h2}
        title={gradientTitle}
        lineBreak={false}
      />
      <Header className="md:text-3xl mb-10" variant={HeaderVariant.h5}>{subtitle}</Header>
      <UITable
        headers={tableHeaders}
        rows={rows}
        actionBar={false}
        shouldHideDefaultActionButtons={false}
        showBorder={true}
        dynamicTableStyling={"shadow-none"}
      />
    </Section>
  );
};

export default Helium10VsSellics;
