import React from "react";
import cn from "classnames";
import Section from "../../../../containers/Section";
import Header, { HeaderVariant } from "../../../Header";
import Paragraph from "../../../Paragraph";

interface Helium10AndSellicsProps {
  helium10AndSellics?: any;
}

const Helium10AndSellics: React.FC<Helium10AndSellicsProps> = props => {
  const { helium10AndSellics } = props;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 items-center mb-20 lg:mb-24">
      {helium10AndSellics?.map((item: any, index: number) => {
        return (
          <div className="mt-22 lg:max-w-xl" key={index}>
            <Header
              className={cn(
                "mb-8 text-left md:font-bold",
                index === 1 &&
                  "bg-gradient-to-r bg-clip-text from-purple-1 to-blue-1 text-transparent animate-gradientRotate"
              )}
              variant={HeaderVariant.h3}
            >
              {item?.title}
            </Header>
            <Paragraph>{item?.description}</Paragraph>
            <div className="lg:max-w-xl relative">
              {item?.faqs.map((faq: any, index: number) => {
                return (
                  <div>
                    <Header key={index} variant={HeaderVariant.h6}>
                    {faq?.question}
                    </Header>
                    <Paragraph key={index} className="text-gray-3-dark">
                      {faq?.answer}
                    </Paragraph>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Helium10AndSellics;
