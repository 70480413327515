import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import "../components/VideoAdsPage/video-ads-page.scss";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import BottomCTA from "../components/BottomCta";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Name, Type, Location, Category } from "../utils/analytics/constants";
import { useSegment } from "../utils/analytics";
import { nav } from "../utils/navigation";
import Alternatives from "../components/CompetitorPage/Alternatives";
import Helium10VsSellics from "../components/CompetitorPage/Helium10VsSellics";
import UnderstandYourProfit from "../components/CompetitorPage/UnderstandYourProfit";
import AnalyzeYourAccount from "../components/CompetitorPage/AnalyzeYourAccount";
import Section from "../containers/Section";
import RowImageContent from "../components/RowImageContent";
import { PAGE } from "../constants/pages";
import { HeaderVariant } from "../components/Header";

interface CompetitorProps {
  data: any;
}

const Competitor: React.FC<CompetitorProps> = ({ data }) => {
  const pageData = data;
  const { t } = useTranslation();
  const { helpers } = useSegment();

  const heroCta = {
    text: t("Get Started"),
    route: t("/demo")
  };

  const alternativesData = {
    title: t("Are you seeking a top Helium 10 alternative?"),
    description: [
      t(
        "In your search for the best Amazon seller software, you probably want to know how Helium 10 compares to Sellics."
      ),
      t(
        "Helium 10 has a variety of tools designed to help sellers optimize their Amazon listings and find new products to sell on Amazon."
      ),
      t(
        "Sellics offers an All-in-One solution for Amazon sellers, with advanced features including Amazon PPC automation, profit tracking & analytics, customer review management, and more."
      )
    ],
    helium10: {
      title: t("Helium 10"),
      description: t(
        "Helium 10 offers a suite of tools to help Amazon sellers with product research, keyword research, and listing optimization on Amazon."
      ),
      faqs: [
        {
          question: t("Who is it suitable for?"),
          answer: t(
            "The platform is suitable for sellers new to FBA, as the tools available focus on helping sellers with their initial product launch stage."
          )
        },
        {
          question: t("Who is it not suitable for?"),
          answer: t(
            "It is not suitable for sellers looking for sophisticated Amazon marketing software (SEO + PPC) to help them acquire new customers and boost revenue growth on Amazon."
          )
        }
      ]
    },
    sellics: {
      title: t("Sellics (now Perpetua)"),
      description: t(
        "Sellics was designed from the ground up to be a one-stop shop for Amazon sellers — one centralized platform where sellers have all the tools they need to turn their Private Label business into a powerful brand on Amazon."
      ),
      faqs: [
        {
          question: t("Who is it suitable for?"),
          answer: t(
            "The platform is suitable for growing sellers looking for sophisticated tools to support their growth and profitability goals on Amazon."
          )
        },
        {
          question: t("Who is it not suitable for?"),
          answer: t(
            "It is not suitable for sellers looking to purchase single-use features within the platform."
          )
        }
      ]
    }
  };

  const features = [
    {
      img: (
        <StaticImage
          src="../images/competitor/profit-dashboard.png"
          alt="Profit Dashboard"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Track your profit in real-time"),
      subtitle: t("Profit Dashboard"),
      description: t(
        "Fully automated calculations of your real profit on Amazon. We automatically deduct your Amazon fees, shipping (i.e. FBA), PPC fees, promos, taxes, and cost of goods."
      ),
      cta: {
        text: t("Learn more"),
        route: t("/amazon-profit-calculator/")
      },
      imgOrder: "order-2",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/competitor/ppc-manager.png"
          alt="PPC Manager"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Maximize your Amazon PPC revenue"),
      subtitle: t("PPC Manager"),
      description: t(
        "Save time and automate your PPC campaigns. Focus on campaign profitability and get the most out of your Amazon advertising spend."
      ),
      cta: {
        text: t("Learn more"),
        route: t("/amazon-seo-tool/")
      },
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/competitor/ranking-optimizer.png"
          alt="Amazon Ranking Optimizer"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Boost your keyword rankings"),
      subtitle: t("Amazon Ranking Optimizer"),
      description: t(
        "Get your products ranked higher on Amazon. Research high-volume keywords, optimize listings, track keyword rankings, and much more."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    },
    {
      img: (
        <StaticImage
          src="../images/competitor/review-management.png"
          alt="Review Management"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Get notified of new reviews"),
      subtitle: t("Review Management"),
      description: t(
        "Get notified for new reviews you receive on Amazon. Sort your reviews by their impact on your average star rating, and prioritize your response rate accordingly."
      ),
      cta: {
        text: t("Learn more"),
        route: t("/blog-amazon-customer-feedback-management-tool/")
      },
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/competitor/inventory.png"
          alt="Inventory Management"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Never run out of inventory again"),
      subtitle: t("Inventory Management"),
      description: t(
        "Know exactly when you’ll run out of stock based on your recent sales velocity. Specify the lead time for each product and receive alerts for low inventory."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    }
  ];


  return (
    <Layout>
      <SEO
        title={t("Best Helium 10 Alternative: Helium 10 vs. Sellics")}
        description={t("Are you looking for a top Helium 10 alternative? Sellics is suitable for growing Amazon sellers looking for tools to support their growth and profitability goals on Amazon. Helium 10 offers a suite of tools to help sellers with their initial product launch stage on Amazon.")}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />

      <HeroSection
        bgGradient="bg-gradient-to-b from-blue-1-gradient to-white"
        page={PAGE.COMPETITOR}
        h1BadgeShadow={false}
        gradientTitle={[t("Helium 10 vs."), t("Sellics")]}
        gradientPosition={2}
        h1Title={t("Best Helium 10 Alternative")}
        description={t("NOW PERPETUA")}
        cta={heroCta}
        imageReplacement={
          <StaticImage
            src="../images/competitor/hero-img.png"
            alt=""
            className="w-full hidden md:block"
          />
        }
      />
      <Alternatives data={alternativesData} />

      <Helium10VsSellics
        gradientTitle={[t("Helium 10 vs."), t("Sellics (now Perpetua)")]}
        gradientPosition={2}
        subtitle={t("Feature Comparison")}
      />

      <UnderstandYourProfit
        gradientTitle={[
          t("Understand your true"),
          t("Amazon profitability"),
          t("with Sellic (now Perpetua)")
        ]}
        gradientPosition={2}
        description={t(
          "Not all SKUs are equally profitable. Some products might actually lose you money if you factor in all your Amazon costs. We break down each cost metric for you — so you know exactly where you need to take action."
        )}
        cta={{ text: t("Get Started"), route: t("/demo") }}
      />

      <AnalyzeYourAccount
        title={t("Is your competitor’s ACOS lower than yours?")}
        description={t(
          "Analyze your Amazon Advertising Account performance for FREE."
        )}
        cta={{
          text: t("Analyze Your Account"),
          route: t("/amazon-advertising-benchmark-tool/")
        }}
      />

      <Section className="pt-12">
        {features?.map((item, index) => {
          return (
            <RowImageContent
              key={index}
              image={item.img}
              title={item.title}
              headerVariant={HeaderVariant.h2}
              subtitle={item.subtitle}
              description={[item.description]}
              cta={item.cta}
              imgOrder={item.imgOrder}
              contentClassName={item.contentClassName}
            />
          );
        })}
      </Section>

      <BottomCTA
        title={t("Try Perpetua today.")}
        primaryButtonText={t("Get Started")}
        primaryButtonOnClick={() =>
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Get Started",
              page_url: window?.location.href,
              destination: t("/demo/"),
              location: Location.BottomCta
            })
            .then(() => {
              nav(t("/demo/"));
            })
        }
      />
    </Layout>
  );
};

export default Competitor;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(
      relativePath: { eq: "competitor/ogImage.png" }
    ) {
      publicURL
    }
  }
`;
